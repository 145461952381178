import {
  H1Icon,
  H2Icon,
  H3Icon,
  IdentificationIcon,
  LanguageIcon,
  ListBulletIcon,
  MinusIcon,
  NumberedListIcon,
  PhotoIcon,
} from "@heroicons/react/16/solid";
import { Extension } from "@tiptap/core";
import { PluginKey } from "@tiptap/pm/state";
import { ReactRenderer } from "@tiptap/react";
import Suggestion from "@tiptap/suggestion";
import toast from "react-hot-toast";
import tippy from "tippy.js";
import "tippy.js/animations/shift-away-subtle.css";
import CommandList from "../../components/command-list";

const renderItems = () => {
  let component = null;
  let popup = null;

  return {
    onStart: (props) => {
      if (!props.editor) {
        return;
      }

      component = new ReactRenderer(CommandList, {
        props,
        editor: props.editor,
      });

      if (!props.clientRect) {
        return;
      }

      popup = tippy("body", {
        getReferenceClientRect: props.clientRect,
        appendTo: () => document.body,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: "manual",
        placement: "bottom-start",
        animation: "shift-away-subtle",
        duration: 150,
      });
    },
    onUpdate: (props) => {
      component?.updateProps(props);

      if (!props.clientRect) {
        return;
      }

      popup &&
        popup?.[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
    },
    onKeyDown: (props) => {
      if (props.event.key === "Escape") {
        popup?.[0].hide();

        return true;
      }

      return component?.ref?.onKeyDown(props);
    },
    onExit: () => {
      if (popup && !popup?.[0].state.isDestroyed) {
        popup?.[0].destroy();
      }

      if (component) {
        component.destroy();
      }
    },
  };
};

const CommandGroups = {
  Blocks: [
    {
      id: 0,
      title: "Text",
      description: "Just start typing with plain text.",
      searchTerms: ["p", "paragraph"],
      icon: LanguageIcon,
      command: ({ editor, range }) => {
        editor?.chain().focus().deleteRange(range).run();

        const currentNode = editor?.state.selection.$from.parent;

        if (currentNode?.content.size !== 0) {
          editor?.commands.enter();
        }

        editor?.commands.toggleNode("paragraph", "paragraph");
      },
    },
    // {
    //     title: 'To-do list',
    //     description: 'Track tasks with a to-do list.',
    //     searchTerms: ['todo', 'task', 'list', 'check', 'checkbox'],
    //     icon: IconCheckbox,
    //     command: ({ editor, range }: CommandProps) => {
    //         editor.chain().focus().deleteRange(range).toggleTaskList().run();
    //     },
    // },
    {
      id: 1,
      title: "Heading 1",
      description: "Big section heading.",
      searchTerms: ["title", "big", "large"],
      icon: H1Icon,
      command: ({ editor, range }) => {
        editor?.chain().focus().deleteRange(range).run();

        const currentNode = editor?.state.selection.$from.parent;

        if (currentNode?.content.size !== 0) {
          editor?.commands.enter();
        }

        editor?.commands.setNode("heading", { level: 1 });
      },
    },
    {
      id: 2,
      title: "Heading 2",
      description: "Medium section heading.",
      searchTerms: ["subtitle", "medium"],
      icon: H2Icon,
      command: ({ editor, range }) => {
        editor?.chain().focus().deleteRange(range).run();

        const currentNode = editor?.state.selection.$from.parent;

        if (currentNode?.content.size !== 0) {
          editor?.commands.enter();
        }

        editor?.commands.setNode("heading", { level: 2 });
      },
    },
    {
      id: 3,
      title: "Heading 3",
      description: "Small section heading.",
      searchTerms: ["subtitle", "small"],
      icon: H3Icon,
      command: ({ editor, range }) => {
        editor?.chain().focus().deleteRange(range).run();

        const currentNode = editor?.state.selection.$from.parent;

        if (currentNode?.content.size !== 0) {
          editor?.commands.enter();
        }

        editor?.commands.setNode("heading", { level: 3 });
      },
    },
    {
      id: 4,
      title: "Bullet list",
      description: "Create a simple bullet list.",
      searchTerms: ["unordered", "point", "list"],
      icon: ListBulletIcon,
      command: ({ editor, range }) => {
        editor?.chain().focus().deleteRange(range).run();

        const currentNode = editor?.state.selection.$from.parent;

        if (currentNode?.content.size !== 0) {
          editor?.commands.enter();
        }

        editor?.commands.toggleBulletList();
      },
    },
    {
      id: 5,
      title: "Numbered list",
      description: "Create a list with numbering.",
      searchTerms: ["numbered", "ordered", "list"],
      icon: NumberedListIcon,
      command: ({ editor, range }) => {
        editor?.chain().focus().deleteRange(range).run();

        const currentNode = editor?.state.selection.$from.parent;

        if (currentNode?.content.size !== 0) {
          editor?.commands.enter();
        }

        editor?.commands.toggleOrderedList();
      },
    },
    {
      id: 6,
      title: "Divider",
      description: "Divide blocks with a horizontal rule.",
      searchTerms: ["divider", "horizontal", "rule"],
      icon: MinusIcon,
      command: ({ editor, range }) => {
        editor?.chain().focus().deleteRange(range).run();

        const currentNode = editor?.state.selection.$from.parent;

        if (currentNode?.content.size !== 0) {
          editor?.commands.enter();
        }

        editor?.commands.setHorizontalRule();
      },
    },
    // {
    //     title: 'Quote',
    //     description: 'Create block quote.',
    //     searchTerms: ['blockquote', 'quotes'],
    //     icon: IconBlockquote,
    //     command: ({ editor, range }: CommandProps) =>
    //         editor.chain().focus().deleteRange(range).toggleBlockquote().run(),
    // },
    // {
    //     title: 'Code',
    //     description: 'Capture a code snippet.',
    //     searchTerms: ['codeblock'],
    //     icon: IconCode,
    //     command: ({ editor, range }: CommandProps) =>
    //         editor.chain().focus().deleteRange(range).toggleCodeBlock().run(),
    // },
    // {
    //     title: 'Image',
    //     description: 'Upload an image from your computer.',
    //     searchTerms: ['photo', 'picture', 'media'],
    //     icon: IconPhoto,
    //     command: ({ editor, range }) => {
    //         editor.chain().focus().deleteRange(range).run();

    //         const pageId = editor.storage?.pageId;
    //         if (!pageId) return;

    //         // upload image
    //         const input = document.createElement('input');
    //         input.type = 'file';
    //         input.accept = 'image/*';
    //         input.onchange = async () => {
    //             if (input.files?.length) {
    //                 const file = input.files[0];
    //                 const pos = editor.view.state.selection.from;
    //                 uploadImageAction(file, editor.view, pos, pageId);
    //             }
    //         };
    //         input.click();
    //     },
    // },
    // {
    //     title: 'Video',
    //     description: 'Upload an video from your computer.',
    //     searchTerms: ['video', 'mp4', 'media'],
    //     icon: IconMovie,
    //     command: ({ editor, range }) => {
    //         editor.chain().focus().deleteRange(range).run();

    //         const pageId = editor.storage?.pageId;
    //         if (!pageId) return;

    //         // upload video
    //         const input = document.createElement('input');
    //         input.type = 'file';
    //         input.accept = 'video/*';
    //         input.onchange = async () => {
    //             if (input.files?.length) {
    //                 const file = input.files[0];
    //                 const pos = editor.view.state.selection.from;
    //                 uploadVideoAction(file, editor.view, pos, pageId);
    //             }
    //         };
    //         input.click();
    //     },
    // },
    // {
    //     title: 'Table',
    //     description: 'Insert a table.',
    //     searchTerms: ['table', 'rows', 'columns'],
    //     icon: IconTable,
    //     command: ({ editor, range }: CommandProps) =>
    //         editor.chain().focus().deleteRange(range).insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run(),
    // },
    // {
    //     title: 'Toggle block',
    //     description: 'Insert collapsible block.',
    //     searchTerms: ['collapsible', 'block', 'toggle', 'details', 'expand'],
    //     icon: IconCaretRightFilled,
    //     command: ({ editor, range }: CommandProps) =>
    //         editor.chain().focus().deleteRange(range).toggleDetails().run(),
    // },
    // {
    //     title: 'Callout',
    //     description: 'Insert callout notice.',
    //     searchTerms: ['callout', 'notice', 'panel', 'info', 'warning', 'success', 'error', 'danger'],
    //     icon: IconInfoCircle,
    //     command: ({ editor, range }: CommandProps) =>
    //         editor.chain().focus().deleteRange(range).toggleCallout().run(),
    // },
    // {
    //     title: 'Math inline',
    //     description: 'Insert inline math equation.',
    //     searchTerms: [
    //         'math',
    //         'inline',
    //         'mathinline',
    //         'inlinemath',
    //         'inline math',
    //         'equation',
    //         'katex',
    //         'latex',
    //         'tex',
    //     ],
    //     icon: IconMathFunction,
    //     command: ({ editor, range }: CommandProps) =>
    //         editor.chain().focus().deleteRange(range).setMathInline().setNodeSelection(range.from).run(),
    // },
    // {
    //     title: 'Math block',
    //     description: 'Insert math equation',
    //     searchTerms: ['math', 'block', 'mathblock', 'block math', 'equation', 'katex', 'latex', 'tex'],
    //     icon: IconMath,
    //     command: ({ editor, range }: CommandProps) =>
    //         editor.chain().focus().deleteRange(range).setMathBlock().run(),
    // },
  ],

  Media: [
    {
      id: 7,
      title: "Image",
      caption: "max 1 MB",
      description: "Upload image",
      searchTerms: ["image", "picture", "upload", "media", "photo"],
      icon: PhotoIcon,
      command: ({ editor, range }) => {
        editor?.chain().focus().deleteRange(range).run();

        const currentNode = editor?.state.selection.$from.parent;

        if (currentNode?.content.size !== 0) {
          editor?.commands.enter();
        }

        const handleImageUpload = (file) => {
          const reader = new FileReader();

          reader.onload = (e) => {
            const src = e.target.result;
            const img = new Image();

            img.onload = () => {
              editor
                ?.chain()
                .focus()
                .setImage({
                  src,
                  width: img.width,
                  originalWidth: img.width,
                  originalHeight: img.height,
                })
                .run();
              requestAnimationFrame(() => editor?.commands.enter());
            };

            img.src = src;
          };

          reader.readAsDataURL(file);
        };

        // Show file upload dialog
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = (event) => {
          const file = event.target.files?.[0];
          const maxSize = 1 * 1024 * 1024; // 1MB in bytes

          if (file && file.size <= maxSize) {
            handleImageUpload(file);
          } else {
            toast.error("Please select an image under 1 MB.");
          }
        };
        input.click();
      },
    },
  ],

  "Contact Fields": [
    {
      id: 8,
      title: "First name",
      description: "First name",
      searchTerms: ["first", "name"],
      icon: IdentificationIcon,
      command: ({ editor, range }) => {
        editor
          ?.chain()
          .focus()
          .deleteRange(range)
          .insertContent({
            type: "dynamicField",
            attrs: { label: "first_name" },
          })
          .run();
      },
    },
    {
      id: 9,
      title: "Last name",
      description: "Last name",
      searchTerms: ["last", "name"],
      icon: IdentificationIcon,
      command: ({ editor, range }) => {
        editor
          ?.chain()
          .focus()
          .deleteRange(range)
          .insertContent({
            type: "dynamicField",
            attrs: { label: "last_name" },
          })
          .run();
      },
    },
    {
      id: 10,
      title: "Email",
      description: "Email",
      searchTerms: ["email", "address", "mail"],
      icon: IdentificationIcon,
      command: ({ editor, range }) => {
        editor
          ?.chain()
          .focus()
          .deleteRange(range)
          .insertContent({
            type: "dynamicField",
            attrs: { label: "email" },
          })
          .run();
      },
    },
    {
      id: 11,
      title: "Is subscribed",
      description: "Is subscribed",
      searchTerms: ["subscribed", "is", "subscribe", "unsubscribe"],
      icon: IdentificationIcon,
      command: ({ editor, range }) => {
        editor
          ?.chain()
          .focus()
          .deleteRange(range)
          .insertContent({
            type: "dynamicField",
            attrs: { label: "is_subscribed" },
          })
          .run();
      },
    },
  ],
};

const getSuggestionItems = ({ query }) => {
  const search = query.toLowerCase();
  const filteredGroups = {};

  for (const [group, items] of Object.entries(CommandGroups)) {
    const filteredItems = items.filter((item) => {
      return (
        item.title.toLowerCase().includes(search) ||
        item.description.toLowerCase().includes(search) ||
        (item.searchTerms && item.searchTerms.some((term) => term.includes(search)))
      );
    });

    if (filteredItems.length) {
      filteredGroups[group] = filteredItems;
    }
  }

  return filteredGroups;
};

const Command = Extension.create({
  name: "slashCommand",

  addOptions() {
    return {
      suggestion: {
        char: "/",
        command: ({ editor, range, props }) => {
          props.command({ editor, range, props });
        },
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        pluginKey: new PluginKey("slashCommand"),
        ...this.options.suggestion,
        editor: this.editor,
      }),
    ];
  },
});

export const SlashCommand = Command.configure({
  suggestion: {
    items: getSuggestionItems,
    render: renderItems,
  },
});
