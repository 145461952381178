import { Heading } from "./heading";

export default function Greeting({ firstName }) {
  const getGreeting = () => {
    const hour = new Date().getHours();

    if (hour >= 5 && hour < 12) {
      return "Good morning";
    } else if (hour >= 12 && hour < 17) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  };

  return (
    <Heading>
      {getGreeting()}, {firstName}
    </Heading>
  );
}
