import { Alert, AlertActions, AlertDescription, AlertTitle } from "@/components/alert";
import { Button } from "@/components/button";
import { Strong } from "../../components/text";

export default function SendAlert({ isOpen, setIsOpen, contactsCopy, onSend }) {
  return (
    <Alert open={isOpen} onClose={setIsOpen}>
      <AlertTitle>Are you sure you want to send this campaign?</AlertTitle>
      <AlertDescription>
        You'll send this campaign to <Strong>{contactsCopy}</Strong>. It can't be undone.
      </AlertDescription>
      <AlertActions>
        <Button plain onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          color="violet"
          onClick={() => {
            setIsOpen(false);
            onSend();
          }}
        >
          Send
        </Button>
      </AlertActions>
    </Alert>
  );
}
