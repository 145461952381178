import * as Headless from "@headlessui/react";
import {
  ArrowPathIcon,
  Bars3BottomLeftIcon,
  Bars3BottomRightIcon,
  Bars3Icon,
  TrashIcon,
} from "@heroicons/react/16/solid";
import { forwardRef, useCallback } from "react";
import { Dropdown, DropdownItem, DropdownLabel, DropdownMenu } from "../../components/dropdown";

const SCROLL_THRESHOLD = 50;
const SCROLL_OFFSET = 500;

const BlockMenu = forwardRef(({ editor, node, getPos }, ref) => {
  const handleDrag = useCallback((event) => {
    const scrollY = window.scrollY;

    if (event.clientY < SCROLL_THRESHOLD) {
      window.scrollTo({ top: scrollY - SCROLL_OFFSET, behavior: "smooth" });
    } else if (window.innerHeight - event.clientY < SCROLL_THRESHOLD) {
      window.scrollTo({ top: scrollY + SCROLL_OFFSET, behavior: "smooth" });
    }
  }, []);

  return (
    <Dropdown>
      <Headless.MenuButton
        className="rounded hover:bg-zinc-100"
        ref={ref}
        draggable
        data-drag-handle
        onDrag={handleDrag}
      >
        <GripVertical className="h-6 w-5 text-zinc-300" />
      </Headless.MenuButton>

      <DropdownMenu anchor="left start" className="![--anchor-gap:theme(spacing.1)]">
        {node.type.name === "image" && (
          <>
            <DropdownItem
              onClick={() => {
                editor
                  .chain()
                  .focus()
                  .setImage({
                    src: node.attrs.src,
                    width: node.attrs.width,
                    originalWidth: node.attrs.originalWidth,
                    originalHeight: node.attrs.originalHeight,
                    alignment: "left",
                  })
                  .run();
              }}
            >
              <Bars3BottomLeftIcon data-slot="icon" />
              <DropdownLabel>Align left</DropdownLabel>
            </DropdownItem>

            <DropdownItem
              onClick={() => {
                editor
                  .chain()
                  .focus()
                  .setImage({
                    src: node.attrs.src,
                    width: node.attrs.width,
                    originalWidth: node.attrs.originalWidth,
                    originalHeight: node.attrs.originalHeight,
                    alignment: "center",
                  })
                  .run();
              }}
            >
              <Bars3Icon data-slot="icon" />
              <DropdownLabel>Align center</DropdownLabel>
            </DropdownItem>

            <DropdownItem
              onClick={() => {
                editor
                  .chain()
                  .focus()
                  .setImage({
                    src: node.attrs.src,
                    width: node.attrs.width,
                    originalWidth: node.attrs.originalWidth,
                    originalHeight: node.attrs.originalHeight,
                    alignment: "right",
                  })
                  .run();
              }}
            >
              <Bars3BottomRightIcon data-slot="icon" />
              <DropdownLabel>Align right</DropdownLabel>
            </DropdownItem>

            <DropdownItem
              onClick={() => {
                const handleImageUpload = (editor, file) => {
                  const reader = new FileReader();

                  reader.onload = (e) => {
                    const src = e.target.result;
                    const img = new Image();

                    img.onload = () => {
                      editor
                        .chain()
                        .focus()
                        .setImage({ src, width: img.width, originalWidth: img.width, originalHeight: img.height })
                        .run();
                      requestAnimationFrame(() => editor.commands.enter());
                    };

                    img.src = src;
                  };

                  reader.readAsDataURL(file);
                };

                // Show file upload dialog
                const input = document.createElement("input");
                input.type = "file";
                input.accept = "image/*";
                input.onchange = (event) => {
                  const file = event.target.files?.[0];
                  const maxSize = 1 * 1024 * 1024; // 1MB in bytes

                  if (file && file.size <= maxSize) {
                    handleImageUpload(editor, file);
                  } else {
                    toast.error("Please select an image under 1 MB.");
                  }
                };
                input.click();
              }}
            >
              <ArrowPathIcon data-slot="icon" />
              <DropdownLabel>Replace</DropdownLabel>
            </DropdownItem>
          </>
        )}

        <DropdownItem
          onClick={() => {
            const pos = getPos();
            editor.commands.deleteRange({ from: pos, to: pos + node.nodeSize });
          }}
        >
          <TrashIcon data-slot="icon" />
          <DropdownLabel>Delete</DropdownLabel>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
});

const GripVertical = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      <circle cx="9" cy="12" r="1" />
      <circle cx="9" cy="5" r="1" />
      <circle cx="9" cy="19" r="1" />
      <circle cx="15" cy="12" r="1" />
      <circle cx="15" cy="5" r="1" />
      <circle cx="15" cy="19" r="1" />
    </svg>
  );
};

export default BlockMenu;
