import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useSidebarStore = create()(
  persist(
    (set) => ({
      isSidebarOpen: true,
      toggleSidebar: () => set((state) => ({ isSidebarOpen: !state.isSidebarOpen })),
    }),
    {
      name: "sidebar-storage",
    }
  )
);
