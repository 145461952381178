import { Head } from "@inertiajs/react";
import { useState } from "react";
import { Button } from "../../components/button";
import { Heading } from "../../components/heading";
import Tab from "../../components/tab";
import { cn } from "../../lib/utils";
import AddTagDialog from "./add-tag-dialog";
import EditTagDialog from "./edit-tag-dialog";
import Empty from "./empty";
import List from "./list";

const tabs = [
  { name: "All contacts", href: "/contacts", current: false },
  { name: "Segments", href: "/segments", current: false },
  { name: "Tags", href: "/tags", current: true },
  { name: "Fields", href: "/fields", current: false },
];

export default function Index({ tags, current_user, path }) {
  const [isAddTagDialogOpen, setIsAddTagDialogOpen] = useState(false);
  const [isEditTagDialogOpen, setIsEditTagDialogOpen] = useState(false);
  const [editingTag, setEditingTag] = useState(null);

  return (
    <>
      <Head title="Tags" />

      <div className="flex items-end justify-between gap-4">
        <Heading>Contacts</Heading>
        <div className="flex gap-4">
          <Button
            color="violet"
            className={cn(tags.length === 0 && "invisible")}
            onClick={() => setIsAddTagDialogOpen(true)}
          >
            Add tag
          </Button>
        </div>
      </div>

      <Tab tabs={tabs} />

      {tags.length > 0 ? (
        <List
          tags={tags}
          onEdit={(tag) => {
            setEditingTag(tag);
            setIsEditTagDialogOpen(true);
          }}
        />
      ) : (
        <Empty onAdd={() => setIsAddTagDialogOpen(true)} />
      )}

      <AddTagDialog isOpen={isAddTagDialogOpen} setIsOpen={setIsAddTagDialogOpen} />
      <EditTagDialog isOpen={isEditTagDialogOpen} setIsOpen={setIsEditTagDialogOpen} tag={editingTag} />
    </>
  );
}
