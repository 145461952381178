import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import { formatDistanceToNow } from "date-fns";
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from "../../components/dropdown";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/table";

export default function List({ tags, onEdit }) {
  return (
    <Table className="mt-8 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
      <TableHead>
        <TableRow>
          <TableHeader>Name</TableHeader>
          <TableHeader>Contacts count</TableHeader>
          <TableHeader>Created at</TableHeader>
          <TableHeader className="relative w-0">
            <span className="sr-only">Actions</span>
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {tags.map((tag) => (
          <TableRow key={tag.id}>
            <TableCell>{tag.name}</TableCell>
            <TableCell>{tag.contacts_count}</TableCell>
            <TableCell className="text-zinc-500">{formatDistanceToNow(tag.created_at, { addSuffix: true })}</TableCell>
            <TableCell>
              <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                <Dropdown>
                  <DropdownButton plain aria-label="More options">
                    <EllipsisHorizontalIcon />
                  </DropdownButton>
                  <DropdownMenu anchor="bottom end">
                    <DropdownItem onClick={() => onEdit(tag)}>
                      <DropdownLabel>Edit</DropdownLabel>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
