import { useState } from "react";
import { Button } from "../../components/button";
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from "../../components/dialog";
import { Field, Label } from "../../components/fieldset";
import { Input } from "../../components/input";

export default function ChangeSubjectDialog({ isOpen, setIsOpen, subject, onSubmit }) {
  const [currentSubject, setCurrentSubject] = useState(subject);

  function handleSubmit() {
    onSubmit(currentSubject);
    setIsOpen(false);
  }

  return (
    <Dialog open={isOpen} onClose={setIsOpen}>
      <DialogTitle>Change subject</DialogTitle>
      <DialogDescription>Use up to 10 words for better open rates.</DialogDescription>
      <DialogBody>
        <Field>
          <Label>Subject</Label>
          <Input
            name="subject"
            placeholder="Welcome onboard!"
            value={currentSubject}
            autoFocus
            onChange={(e) => setCurrentSubject(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          />
        </Field>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button color="violet" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
