import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cn } from "../lib/utils";

export default function CommandList({ items, command, editor, range }) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const viewportRef = useRef(null);

  const flatItems = useMemo(() => {
    return Object.values(items).flat();
  }, [items]);

  const selectItem = useCallback(
    (index) => {
      const item = flatItems[index];
      if (item) {
        command(item);
      }
    },
    [command, flatItems]
  );

  useEffect(() => {
    const navigationKeys = ["ArrowUp", "ArrowDown", "Enter"];
    const onKeyDown = (e) => {
      if (navigationKeys.includes(e.key)) {
        e.preventDefault();

        if (e.key === "ArrowUp") {
          setSelectedIndex((selectedIndex + flatItems.length - 1) % flatItems.length);
          return true;
        }

        if (e.key === "ArrowDown") {
          setSelectedIndex((selectedIndex + 1) % flatItems.length);
          return true;
        }

        if (e.key === "Enter") {
          selectItem(selectedIndex);
          return true;
        }

        return false;
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [flatItems, selectedIndex, setSelectedIndex, selectItem]);

  useEffect(() => {
    setSelectedIndex(0);
  }, [flatItems]);

  useEffect(() => {
    const selectedId = flatItems[selectedIndex].id;
    const scrollTarget = selectedIndex === 0 ? "#menu-heading-0" : `#menu-item-${selectedId}`;
    viewportRef.current?.querySelector(scrollTarget)?.scrollIntoView({ block: "nearest" });
  }, [selectedIndex]);

  return flatItems.length > 0 ? (
    <div
      ref={viewportRef}
      id="slash-command"
      className={cn(
        // Base styles
        "isolate w-max rounded-xl p-1",
        // Invisible border that is only visible in `forced-colors` mode for accessibility purposes
        "outline outline-1 outline-transparent focus:outline-none",
        // Handle scrolling when menu won't fit in viewport
        "overflow-y-auto",
        // Popover background
        "dark:bg-zinc-800/75 bg-white/75 backdrop-blur-xl",
        // Shadows
        "dark:ring-inset dark:ring-white/10 shadow-lg ring-1 ring-zinc-950/10",
        // Define grid at the menu level if subgrid is supported
        "supports-[grid-template-columns:subgrid]:grid supports-[grid-template-columns:subgrid]:grid-cols-[auto_1fr_1.5rem_0.5rem_auto]",
        // Limit the height of the menu
        "max-h-[272px]"
      )}
      tabIndex={0}
      role="menu"
      aria-labelledby="menu-button"
    >
      {Object.entries(items).map(([category, categoryItems], categoryIndex) => (
        <div
          key={category}
          className={cn(
            // Define grid at the section level instead of the item level if subgrid is supported
            "col-span-full supports-[grid-template-columns:subgrid]:grid supports-[grid-template-columns:subgrid]:grid-cols-[auto_1fr_1.5rem_0.5rem_auto]"
          )}
          role="group"
          aria-labelledby={`menu-heading-${categoryIndex}`}
        >
          <header
            className="dark:text-zinc-400 col-span-full grid grid-cols-[1fr,auto] gap-x-12 px-3.5 pb-1 pt-2 text-sm/5 font-medium text-zinc-500 sm:px-3 sm:text-xs/5"
            id={`menu-heading-${categoryIndex}`}
            role="presentation"
          >
            {category}
          </header>

          {categoryItems.map((item) => (
            <button
              key={item.id}
              type="button"
              id={`menu-item-${item.id}`}
              className={cn(
                // Base styles
                "group cursor-default rounded-lg px-3.5 py-2.5 focus:outline-none sm:px-3 sm:py-1.5",
                // Text styles
                "dark:text-white text-left text-base/6 text-zinc-950 sm:text-sm/6 forced-colors:text-[CanvasText]",
                // Focus
                "data-[focus]:bg-blue-500 data-[focus]:text-white",
                // Disabled state
                "data-[disabled]:opacity-50",
                // Forced colors mode
                "forced-color-adjust-none forced-colors:data-[focus]:bg-[Highlight] forced-colors:data-[focus]:text-[HighlightText] forced-colors:[&>[data-slot=icon]]:data-[focus]:text-[HighlightText]",
                // Use subgrid when available but fallback to an explicit grid layout if not
                "col-span-full grid grid-cols-[auto_1fr_1.5rem_0.5rem_auto] items-center supports-[grid-template-columns:subgrid]:grid-cols-subgrid",
                // Icons
                "[&>[data-slot=icon]]:col-start-1 [&>[data-slot=icon]]:row-start-1 [&>[data-slot=icon]]:-ml-0.5 [&>[data-slot=icon]]:mr-2.5 [&>[data-slot=icon]]:size-5 sm:[&>[data-slot=icon]]:mr-2 [&>[data-slot=icon]]:sm:size-4",
                "[&>[data-slot=icon]]:dark:text-zinc-400 [&>[data-slot=icon]]:data-[focus]:dark:text-white [&>[data-slot=icon]]:text-zinc-500 [&>[data-slot=icon]]:data-[focus]:text-white",
                // Avatar
                "[&>[data-slot=avatar]]:-ml-1 [&>[data-slot=avatar]]:mr-2.5 [&>[data-slot=avatar]]:size-6 sm:[&>[data-slot=avatar]]:mr-2 sm:[&>[data-slot=avatar]]:size-5"
              )}
              {...(item.id === flatItems[selectedIndex].id ? { "data-focus": "" } : {})}
              role="menuitem"
              tabIndex={-1}
              aria-labelledby={`label-${item.id}`}
              onClick={() => selectItem(flatItems.indexOf(item))}
              onMouseOver={() => setSelectedIndex(flatItems.indexOf(item))}
            >
              <item.icon data-slot="icon" />
              <div id={`label-${item.id}`} className="col-start-2 row-start-1" data-slot="label">
                <span>{item.title}</span>
                {item.caption && (
                  <span className="ml-1 text-xs text-zinc-500 group-data-[focus]:text-white">({item.caption})</span>
                )}
              </div>
            </button>
          ))}
        </div>
      ))}
    </div>
  ) : null;
}
