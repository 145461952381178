import { UserGroupIcon } from "@heroicons/react/24/outline";
import { Subheading } from "../../components/heading";
import { Text } from "../../components/text";
import AddContactDropdown from "./add-contact-dropdown";

export default function Empty({ onFillForm, onUploadCsv }) {
  return (
    <div className="text-center mt-40">
      <UserGroupIcon className="mx-auto size-16 text-zinc-400" />
      <Subheading className="mt-2">You have no contacts yet</Subheading>
      <Text className="mt-1">Get started by adding a contact.</Text>
      <div className="mt-6">
        <AddContactDropdown onFillForm={onFillForm} onUploadCsv={onUploadCsv} isContactEmpty={true} />
      </div>
    </div>
  );
}
