import { ChevronLeftIcon } from "@heroicons/react/16/solid";
import { Head } from "@inertiajs/react";
import LinkExtension from "@tiptap/extension-link";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Badge } from "../../components/badge";
import Editor from "../../components/editor";
import { Subheading } from "../../components/heading";
import { Link } from "../../components/link";
import { cn } from "../../lib/utils";
import { DraggableDivider, DraggableImage } from "../../tiptap/extensions/draggable";
import { DynamicField } from "../../tiptap/extensions/dynamic-field";

export default function Show({ current_user, path, campaign }) {
  const editor = useEditor({
    editable: false,
    content: JSON.parse(campaign.json_content ?? ""),
    editorProps: {
      attributes: {
        class: cn("prose prose-base prose-zinc mx-auto max-w-[708px] caret-zinc-900 focus:outline-none"),
      },
    },
    extensions: [
      StarterKit.configure({ horizontalRule: false }),
      Typography,
      LinkExtension.extend({ inclusive: false }).configure({
        HTMLAttributes: {
          class: "hover:cursor-pointer",
        },
      }),
      DraggableDivider,
      DraggableImage,
      Underline,
      DynamicField,
    ],
  });

  return (
    <>
      <Head title={campaign.subject} />

      <div className="flex justify-between max-lg:items-end items-center">
        <div className="max-lg:hidden">
          <Link href="/campaigns" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
            <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
            Campaigns
          </Link>
        </div>
        <div className="flex items-center gap-x-2">
          <Subheading level={1}>{campaign.subject}</Subheading>
          <Badge color="lime">Sent</Badge>
        </div>
        <div />
      </div>

      <div className="px-4 py-20">
        <Editor editor={editor} />
      </div>
    </>
  );
}
