import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/table";

export default function List({ contacts, fields }) {
  return (
    <Table className="mt-8 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
      <TableHead>
        <TableRow>
          <TableHeader>Email</TableHeader>
          <TableHeader>First name</TableHeader>
          <TableHeader>Last name</TableHeader>
          <TableHeader>Is subscribed</TableHeader>
          {fields.map((field) => (
            <TableHeader key={field.name}>{field.name}</TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {contacts.map((contact) => (
          <TableRow key={contact.id}>
            <TableCell>{contact.email}</TableCell>
            <TableCell>{contact.first_name || "-"}</TableCell>
            <TableCell>{contact.last_name || "-"}</TableCell>
            <TableCell>{contact.is_subscribed ? "Yes" : "No"}</TableCell>
            {contact.custom_fields.map((field) => (
              <TableCell key={field.field_name}>{field.field_value || "-"}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
