import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target.result);
    reader.onerror = (e) => reject(e);
    reader.readAsText(file);
  });
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getEditorNodes = (editor, nodeName) => {
  const nodes = [];

  // Check if the editor exists and get the document
  if (editor && editor.state) {
    editor.state.doc.descendants((node, pos) => {
      if (node.type.name === nodeName) {
        nodes.push({ node, pos });
      }
      return true; // Continue traversing
    });
  }

  return nodes;
};
