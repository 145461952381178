import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";

export const useAutoSave = ({
  saveEndpoint,
  saveInterval,
  initialSubject,
  initialContent,
  initialSenderId,
  initialPreviewText,
}) => {
  const [subject, setSubject] = useState(initialSubject || "");
  const [content, setContent] = useState(initialContent || "");
  const [senderId, setSenderId] = useState(initialSenderId || "");
  const [previewText, setPreviewText] = useState(initialPreviewText || "");
  const timeoutRef = useRef(null);

  const saveContent = useCallback(async () => {
    try {
      await axios.put(saveEndpoint, { subject, json_content: content, sender_id: senderId, preview_text: previewText });
    } catch (error) {
      console.error("Failed to save:", error);
    }
  }, [saveEndpoint, subject, content, senderId, previewText]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(saveContent, saveInterval);
  }, [subject, content, senderId, previewText]);

  return {
    subject,
    setSubject,
    content,
    setContent,
    senderId,
    setSenderId,
    previewText,
    setPreviewText,
  };
};
