import { PlusIcon } from "@heroicons/react/24/outline";
import { NodeViewContent, NodeViewWrapper } from "@tiptap/react";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import Loader from "../../components/loader";
import { cn } from "../../lib/utils";
import { dispatchImageResize, KEYDOWN_KEY, MOUSEMOVE_KEY } from "../../prose-mirror/plugins/event-dispatcher";
import BlockMenu from "./block-menu";

export function BlockHandle({ editor, node, getPos }) {
  const [isActive, setIsActive] = useState(false);
  const [showGrip, setShowGrip] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const contentTag = useMemo(() => {
    if (node.type.name === "paragraph") return "p";
    if (node.type.name === "heading") return `h${node.attrs.level}`;
    if (node.type.name === "horizontalRule") return "hr";
    if (node.type.name === "listItem") return "li";
    if (node.type.name === "image") return "img";
  }, [node]);
  const hasListParent = useMemo(() => {
    const $targetPos = editor.$pos(getPos());
    return $targetPos.closest("bulletList") || $targetPos.closest("orderedList");
  }, [editor, getPos]);
  const placeholder = useMemo(() => {
    if (node.type.name === "paragraph" && hasListParent) return "List";
    if (node.type.name === "paragraph") return "Start writing or press '/' for commands...";
    if (node.type.name === "heading") return `Heading ${node.attrs.level}`;
  }, [node]);
  const isEmpty = useMemo(() => node.content.size === 0, [node.content.size]);

  useEffect(() => {
    if (editor.isEmpty) {
      // Focusing directly doesn't work
      // Using requestAnimationFrame to focus smoothly
      requestAnimationFrame(() => {
        editor.commands.focus();
      });
    }
  }, [editor]);

  useEffect(() => {
    const updateSelection = () => {
      const pos = getPos();
      const { $from, $to } = editor.state.selection;
      const nodeSize = node.nodeSize;

      setIsActive($from.pos >= pos && $to.pos <= pos + nodeSize);
    };

    editor.on("selectionUpdate", updateSelection);

    // Initial check
    updateSelection();

    return () => {
      editor.off("selectionUpdate", updateSelection);
    };
  }, [editor, node, getPos]);

  useEffect(() => {
    const handleTransaction = ({ transaction }) => {
      const keydownMeta = transaction.getMeta(KEYDOWN_KEY);
      const mousemoveMeta = transaction.getMeta(MOUSEMOVE_KEY);

      if (keydownMeta) {
        const keyboardEvent = keydownMeta.event;

        if (!["Control", "Alt", "Meta"].includes(keyboardEvent.key)) {
          setShowGrip(false);
        }
      }

      if (mousemoveMeta) {
        const mouseEvent = mousemoveMeta.event;
        const targetNode = mouseEvent.target;
        const targetPos = editor.view.posAtDOM(targetNode, 0);

        if (node.type.name === "listItem") {
          const $targetPos = editor.$pos(targetPos);

          setShowGrip(
            targetPos === getPos() + 1 ||
              ($targetPos.node.type.name === "paragraph" &&
                $targetPos.parent?.node.type.name === "listItem" &&
                $targetPos.parent?.pos === getPos() + 1)
          );
        } else if (["horizontalRule", "image"].includes(node.type.name)) {
          setShowGrip(targetPos === getPos() || targetPos === getPos() + 1);
        } else {
          setShowGrip(targetPos === getPos() + 1);
        }
      }
    };

    editor.on("transaction", handleTransaction);

    return () => {
      editor.off("transaction", handleTransaction);
    };
  }, [editor, node, getPos]);

  useEffect(() => {
    if (node.type.name === "image" && node.attrs.src && node.attrs.src.startsWith("data:image")) {
      const handleStoreMedia = async () => {
        try {
          // Convert base64 to blob
          const convertResponse = await axios.get(node.attrs.src, { responseType: "blob" });
          const blob = convertResponse.data;

          const formData = new FormData();
          formData.append("file", blob);

          // Get the email id from editorProps.attributes
          const campaignId =
            typeof editor.view.props.attributes === "function"
              ? editor.view.props.attributes(editor.state)?.id
              : editor.view.props.attributes?.id;

          const uploadResponse = await axios.post(`/campaigns/${campaignId}/store-media`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          // Update the specific node at its position
          editor.view.dispatch(
            editor.view.state.tr.setNodeMarkup(getPos(), null, {
              ...node.attrs,
              src: uploadResponse.data.url,
            })
          );
        } catch (error) {
          toast.error("Failed to store media. Please try again.");
        }
      };

      handleStoreMedia();
    }
  }, [node.type.name, node.attrs.src, editor, getPos]);

  const handleResize = (e, direction) => {
    e.preventDefault();

    const startX = e.clientX;
    const startWidth = node.attrs.width;

    setIsResizing(true);

    const onMouseMove = (moveEvent) => {
      const dx = moveEvent.clientX - startX;
      const newWidth = direction === "right" ? startWidth + dx : startWidth - dx;
      const maxWidth = editor.view.dom.offsetWidth;
      const clampedWidth = Math.min(Math.max(newWidth, 50), Math.min(maxWidth, node.attrs.originalWidth));

      // Update the node attributes with the new width
      editor.view.dispatch(
        editor.view.state.tr.setNodeMarkup(getPos(), null, {
          ...node.attrs,
          width: clampedWidth,
        })
      );

      // Dispatch the resize event
      const height = (clampedWidth / node.attrs.originalWidth) * node.attrs.originalHeight;
      dispatchImageResize(editor.view, { width: clampedWidth, height: Math.round(height) });
    };

    const onMouseUp = () => {
      setIsResizing(false);
      dispatchImageResize(editor.view, null);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  function handlePlusClick(event) {
    event.preventDefault();

    const newPos = getPos() + node.nodeSize;

    if (node.type.name === "image") {
      editor
        .chain()
        .insertContentAt(newPos, { type: "paragraph" })
        .focus(newPos + 1)
        .run();

      editor.commands.insertContent("/");

      return;
    }

    if (isEmpty) {
      editor.commands.insertContent("/");
    } else {
      editor
        .chain()
        .insertContentAt(newPos, { type: "paragraph", content: [{ type: "text", text: "/" }] })
        .focus(newPos + 2)
        .run();
    }
  }

  return (
    <NodeViewWrapper className="relative">
      {editor.isEditable && (
        <div
          className={cn("absolute top-0.5", {
            invisible: node.type.name === "paragraph" && hasListParent,
            "-left-1 -translate-x-full": node.type.name !== "listItem",
            "w-[74px] -translate-x-full": node.type.name === "listItem",
          })}
        >
          <div
            className={cn("flex transition-opacity duration-200", {
              "opacity-0": !showGrip,
            })}
          >
            <button className="rounded hover:bg-zinc-100" onClick={handlePlusClick}>
              <PlusIcon className="size-6 text-zinc-300" />
            </button>

            <BlockMenu editor={editor} node={node} getPos={getPos} />
          </div>
        </div>
      )}

      {node.type.name === "horizontalRule" ? (
        <div className="my-[34px] py-3.5">
          <NodeViewContent as="hr" />
        </div>
      ) : node.type.name === "image" ? (
        node.attrs.src.startsWith("data:image") ? (
          <Loader className="size-5 text-violet-500" />
        ) : (
          <div
            className={cn("flex", {
              "justify-start": node.attrs.alignment === "left",
              "justify-center": node.attrs.alignment === "center",
              "justify-end": node.attrs.alignment === "right",
            })}
          >
            <div className="group relative">
              {editor.isEditable && (
                <div
                  className={cn(
                    "absolute bottom-8 left-0 top-8 w-3.5 cursor-col-resize transition-opacity duration-200 group-hover:opacity-100",
                    {
                      "opacity-0": !isResizing,
                    }
                  )}
                  onMouseDown={(e) => handleResize(e, "left")}
                >
                  <div className="flex h-full items-center justify-center">
                    <div className="h-2/6 max-h-12 w-1.5 rounded-md border border-white bg-zinc-700" />
                  </div>
                </div>
              )}

              <NodeViewContent
                as="img"
                src={node.attrs.src}
                alt={node.attrs.alt}
                width={node.attrs.width}
                className="h-auto max-w-full"
              />

              {editor.isEditable && (
                <div
                  className={cn(
                    "absolute bottom-8 right-0 top-8 w-3.5 cursor-col-resize transition-opacity duration-200 group-hover:opacity-100",
                    {
                      "opacity-0": !isResizing,
                    }
                  )}
                  onMouseDown={(e) => handleResize(e, "right")}
                >
                  <div className="flex h-full items-center justify-center">
                    <div className="h-2/6 max-h-12 w-1.5 rounded-md border border-white bg-zinc-700" />
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      ) : (
        <NodeViewContent
          as={contentTag}
          className={cn({
            "before:content-[attr(data-placeholder)]": isEmpty && isActive,
            "before:pointer-events-none before:absolute before:left-0 before:top-0 before:h-0 before:text-zinc-400":
              isEmpty && isActive,
          })}
          data-placeholder={isEmpty && isActive ? placeholder : undefined}
        />
      )}
    </NodeViewWrapper>
  );
}
