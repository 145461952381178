import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import { Badge } from "../../components/badge";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "../../components/dropdown";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/table";

export default function List({ senders, onEdit, onSetDefault, onVerify }) {
  return (
    <Table className="mt-8 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
      <TableHead>
        <TableRow>
          <TableHeader>Identity</TableHeader>
          <TableHeader>Status</TableHeader>
          <TableHeader className="relative w-0">
            <span className="sr-only">Actions</span>
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {senders.map((sender) => (
          <TableRow key={sender.id}>
            <TableCell>
              <div className="text-zinc-500">{sender.name}</div>
              <div>{sender.email}</div>
            </TableCell>
            <TableCell>
              <div className="flex flex-wrap gap-2">
                {sender.default && <Badge color="cyan">Default sender</Badge>}
                {sender.status === "verified" ? (
                  <Badge color="lime">Verified</Badge>
                ) : sender.status === "in progress" ? (
                  <Badge color="yellow">In progress</Badge>
                ) : (
                  <Badge color="pink">Unverified</Badge>
                )}
              </div>
            </TableCell>
            <TableCell>
              <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                <Dropdown>
                  <DropdownButton plain aria-label="More options">
                    <EllipsisHorizontalIcon />
                  </DropdownButton>
                  <DropdownMenu anchor="bottom end">
                    {sender.status === "unverifiable" && (
                      <>
                        <DropdownHeader>
                          <div className="pr-6">
                            <div className="text-xs text-zinc-500 dark:text-zinc-400">Unverifiable domain</div>
                            <div className="text-sm/7 font-semibold text-zinc-800 dark:text-white">
                              We can only verify a domain that you own.
                            </div>
                          </div>
                        </DropdownHeader>
                        <DropdownDivider />
                      </>
                    )}
                    <DropdownItem onClick={() => onEdit(sender)}>
                      <DropdownLabel>Edit</DropdownLabel>
                    </DropdownItem>
                    {!sender.default && (
                      <DropdownItem onClick={() => onSetDefault(sender)}>
                        <DropdownLabel>Set as default</DropdownLabel>
                      </DropdownItem>
                    )}
                    {!!sender.need_verification && (
                      <DropdownItem onClick={() => onVerify(sender)}>
                        <DropdownLabel>Verify identity</DropdownLabel>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
