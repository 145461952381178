import { Head } from "@inertiajs/react";
import { useRef, useState } from "react";
import { Button } from "../../components/button";
import { Heading } from "../../components/heading";
import Tab from "../../components/tab";
import AddContactDialog from "./add-contact-dialog";
import AddContactDropdown from "./add-contact-dropdown";
import Empty from "./empty";
import List from "./list";
import UploadCsv from "./upload-csv";

const tabs = [
  { name: "All contacts", href: "/contacts", current: true },
  { name: "Segments", href: "/segments", current: false },
  { name: "Tags", href: "/tags", current: false },
  { name: "Fields", href: "/fields", current: false },
];

export default function Index({ contacts, fields, tags, current_user, path }) {
  const [isAddContactDialogOpen, setIsAddContactDialogOpen] = useState(false);
  const fileInputRef = useRef(null);

  return (
    <>
      <Head title="Contacts" />

      <div className="flex items-end justify-between gap-4">
        <Heading>Contacts</Heading>
        <div className="flex gap-4">
          {contacts.length > 0 ? (
            <AddContactDropdown
              onFillForm={() => setIsAddContactDialogOpen(true)}
              onUploadCsv={() => fileInputRef.current?.click()}
            />
          ) : (
            <Button className="invisible">Add contact</Button>
          )}
        </div>
      </div>

      <Tab tabs={tabs} />

      {contacts.length > 0 ? (
        <List contacts={contacts} fields={fields} />
      ) : (
        <Empty onFillForm={() => setIsAddContactDialogOpen(true)} onUploadCsv={() => fileInputRef.current?.click()} />
      )}

      <AddContactDialog
        isOpen={isAddContactDialogOpen}
        setIsOpen={setIsAddContactDialogOpen}
        fields={fields}
        tags={tags}
      />
      <UploadCsv ref={fileInputRef} fields={fields} />
    </>
  );
}
