import { Head } from "@inertiajs/react";
import { useState } from "react";
import { Button } from "../../components/button";
import { Heading } from "../../components/heading";
import Tab from "../../components/tab";
import AddFieldDialog from "./add-field-dialog";
import EditFieldDialog from "./edit-field-dialog";
import List from "./list";

const tabs = [
  { name: "All contacts", href: "/contacts", current: false },
  { name: "Segments", href: "/segments", current: false },
  { name: "Tags", href: "/tags", current: false },
  { name: "Fields", href: "/fields", current: true },
];

export default function Index({ fields, current_user, path }) {
  const [isAddFieldDialogOpen, setIsAddFieldDialogOpen] = useState(false);
  const [isEditFieldDialogOpen, setIsEditFieldDialogOpen] = useState(false);
  const [editingField, setEditingField] = useState(null);

  return (
    <>
      <Head title="Fields" />

      <div className="flex items-end justify-between gap-4">
        <Heading>Contacts</Heading>
        <div className="flex gap-4">
          <Button color="violet" onClick={() => setIsAddFieldDialogOpen(true)}>
            Add field
          </Button>
        </div>
      </div>

      <Tab tabs={tabs} />

      <List
        fields={fields}
        onEdit={(field) => {
          setEditingField(field);
          setIsEditFieldDialogOpen(true);
        }}
      />

      <AddFieldDialog isOpen={isAddFieldDialogOpen} setIsOpen={setIsAddFieldDialogOpen} />
      <EditFieldDialog isOpen={isEditFieldDialogOpen} setIsOpen={setIsEditFieldDialogOpen} field={editingField} />
    </>
  );
}
