import { Head } from "@inertiajs/react";
import Greeting from "../../components/greeting";
import { Subheading } from "../../components/heading";
import { Select } from "../../components/select";
import Stat from "../../components/stat";
import ContactsChart from "./contacts-chart";

export default function Index({ first_name, contacts_metrics, contacts_chart_data }) {
  return (
    <>
      <Head title="Dashboard" />

      <Greeting firstName={first_name} />

      <div className="mt-8 flex items-end justify-between">
        <Subheading>Contacts</Subheading>
        <div>
          <Select name="period" className="invisible">
            <option value="last_week">Last week</option>
            <option value="last_two">Last two weeks</option>
            <option value="last_month">Last month</option>
            <option value="last_quarter">Last quarter</option>
          </Select>
        </div>
      </div>

      <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
        <Stat title="Subscribed today" value={contacts_metrics.subscribedToday} />
        <Stat title="Subscribed last 30 days" value={contacts_metrics.subscribedLast30Days} />
        <Stat title="Unsubscribed last 30 days" value={contacts_metrics.unsubscribedLast30Days} />
        <Stat title="Total subscribers" value={contacts_metrics.totalSubscribers} />
      </div>

      <div className="mt-8">
        <ContactsChart data={contacts_chart_data} />
      </div>
    </>
  );
}
