import { Head, router } from "@inertiajs/react";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { Button } from "../../components/button";
import { Heading } from "../../components/heading";
import AddSenderDialog from "./add-sender-dialog";
import EditSenderDialog from "./edit-sender-dialog";
import List from "./list";

export default function Index({ current_user, path, senders, default_sender_id, max_senders }) {
  const [isAddSenderDialogOpen, setIsAddSenderDialogOpen] = useState(false);
  const [isEditSenderDialogOpen, setIsEditSenderDialogOpen] = useState(false);
  const [editingSender, setEditingSender] = useState(null);
  const sortedSendersByDefaultSender = useMemo(() => {
    return senders
      .map((sender) => ({ ...sender, default: sender.id === default_sender_id }))
      .sort((a, b) => (b.default ? 1 : a.default ? -1 : 0));
  }, [senders, default_sender_id]);

  return (
    <>
      <Head title="Senders" />

      <div className="flex items-end justify-between gap-4">
        <Heading>Senders</Heading>
        <div className="flex gap-4">
          <Button
            color="violet"
            onClick={() =>
              senders.length < max_senders
                ? setIsAddSenderDialogOpen(true)
                : toast.error(`You can only add up to ${max_senders} senders.`)
            }
          >
            Add sender
          </Button>
        </div>
      </div>

      <List
        senders={sortedSendersByDefaultSender}
        default_sender_id={default_sender_id}
        onEdit={(sender) => {
          setEditingSender(sender);
          setIsEditSenderDialogOpen(true);
        }}
        onSetDefault={(sender) => {
          router.post(`/senders/${sender.id}/set-default`);
        }}
        onVerify={(sender) => {
          router.get(`/senders/${sender.id}/verification`);
        }}
      />
      <AddSenderDialog isOpen={isAddSenderDialogOpen} setIsOpen={setIsAddSenderDialogOpen} />
      <EditSenderDialog isOpen={isEditSenderDialogOpen} setIsOpen={setIsEditSenderDialogOpen} sender={editingSender} />
    </>
  );
}
