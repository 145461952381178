import { useForm } from "@inertiajs/react";
import { useEffect } from "react";
import { Button } from "../../components/button";
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from "../../components/dialog";
import { ErrorMessage, Field, FieldGroup, Fieldset, Label } from "../../components/fieldset";
import { Input } from "../../components/input";

export default function EditSegmentDialog({ isOpen, setIsOpen, segment }) {
  const { data, errors, put, processing, recentlySuccessful, reset, setData } = useForm({
    name: segment?.name || "",
  });

  useEffect(() => {
    setData({ name: segment?.name || "" });
  }, [segment]);

  useEffect(() => {
    if (recentlySuccessful) {
      reset();
      setIsOpen(false);
    }
  }, [recentlySuccessful]);

  return (
    <Dialog open={isOpen} onClose={setIsOpen}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          put(`/segments/${segment?.id}`);
        }}
      >
        <DialogTitle>Edit segment</DialogTitle>
        <DialogDescription>
          Avoid changing the segment name to minimize confusion unless you know what you are doing.
        </DialogDescription>
        <DialogBody>
          <Fieldset>
            <FieldGroup>
              <Field>
                <Label>Name</Label>
                <Input
                  name="name"
                  placeholder="VIP customers in New York"
                  value={data.name}
                  onChange={(e) => setData("name", e.target.value)}
                  disabled={processing}
                  invalid={!!errors.name}
                  autoFocus
                />
                {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
              </Field>
            </FieldGroup>
          </Fieldset>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)} disabled={processing}>
            Cancel
          </Button>
          <Button color="violet" type="submit" disabled={processing}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
