import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import { formatDistanceToNow } from "date-fns";
import { Badge } from "../../components/badge";
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from "../../components/dropdown";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/table";
import { capitalizeFirstLetter } from "../../lib/utils";

export default function List({ fields, onEdit }) {
  return (
    <Table className="mt-8 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
      <TableHead>
        <TableRow>
          <TableHeader>Name</TableHeader>
          <TableHeader>Type</TableHeader>
          <TableHeader>Source</TableHeader>
          <TableHeader>Created at</TableHeader>
          <TableHeader className="relative w-0">
            <span className="sr-only">Actions</span>
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {fields.map((field) => (
          <TableRow key={field.name}>
            <TableCell>{field.name}</TableCell>
            <TableCell>{capitalizeFirstLetter(field.data_type)}</TableCell>
            <TableCell>
              {field.created_at ? <Badge color="cyan">Added</Badge> : <Badge color="zinc">Built-in</Badge>}
            </TableCell>
            <TableCell className="text-zinc-500">
              {field.created_at ? formatDistanceToNow(field.created_at, { addSuffix: true }) : "-"}
            </TableCell>
            <TableCell>
              <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                {field.created_at && (
                  <Dropdown>
                    <DropdownButton plain aria-label="More options">
                      <EllipsisHorizontalIcon />
                    </DropdownButton>
                    <DropdownMenu anchor="bottom end">
                      <DropdownItem onClick={() => onEdit(field)}>
                        <DropdownLabel>Edit</DropdownLabel>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                )}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
