import { BoldIcon, ItalicIcon, LinkIcon, StrikethroughIcon, UnderlineIcon } from "@heroicons/react/16/solid";
import Tippy from "@tippyjs/react";
import { BubbleMenu, EditorContent, isTextSelection } from "@tiptap/react";
import { useEffect, useRef, useState } from "react";
import { cn } from "../lib/utils";
import LinkInput from "./link-input";

export default function Editor({ editor }) {
  const [showLinkInput, setShowLinkInput] = useState(false);
  const linkButtonRef = useRef(null);

  useEffect(() => {
    if (!editor) {
      return;
    }

    const handleKeydown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "k" && document.querySelector("#formatting-bubble")) {
        if (!showLinkInput) {
          linkButtonRef.current?.focus();
          linkButtonRef.current?.click();
        } else {
          setShowLinkInput(false);
        }
      }
    };

    document.addEventListener("keydown", handleKeydown);

    return () => document.removeEventListener("keydown", handleKeydown);
  }, [editor, showLinkInput]);

  return (
    <>
      <EditorContent editor={editor} />
      {editor && (
        <BubbleMenu
          editor={editor}
          tippyOptions={{ animation: false }}
          updateDelay={0}
          shouldShow={({ editor, view, state, oldState, from, to }) => {
            // Only show when editor is editable
            if (!editor.isEditable) return false;

            // Only show for text selections
            if (!isTextSelection(state.selection)) return false;

            // Don't show for empty selections
            if (from === to) return false;

            // Get the node at the current selection
            const node = state.doc.nodeAt(from);

            // Don't show for image nodes
            if (node?.type.name === "image") return false;

            // Don't show immediately after dragging
            if (editor.isActive("draggable")) return false;

            return true;
          }}
        >
          <div id="formatting-bubble" className="flex rounded-xl bg-white p-1 shadow-lg ring-1 ring-zinc-950/10">
            <button
              type="button"
              className="rounded-lg p-2 hover:bg-zinc-950/5"
              onClick={() => editor?.chain().focus().toggleBold().run()}
            >
              <BoldIcon
                className={cn("text-zinc-500 size-4", {
                  "text-blue-500": editor?.isActive("bold"),
                })}
              />
            </button>

            <button
              type="button"
              className="rounded-lg p-2 hover:bg-zinc-950/5"
              onClick={() => editor?.chain().focus().toggleItalic().run()}
            >
              <ItalicIcon
                className={cn("text-zinc-500 size-4", {
                  "text-blue-500": editor?.isActive("italic"),
                })}
              />
            </button>

            <button
              type="button"
              className="rounded-lg p-2 hover:bg-zinc-950/5"
              onClick={() => editor?.chain().focus().toggleUnderline().run()}
            >
              <UnderlineIcon
                className={cn("text-zinc-500 size-4", {
                  "text-blue-500": editor?.isActive("underline"),
                })}
              />
            </button>

            <button
              type="button"
              className="rounded-lg p-2 hover:bg-zinc-950/5"
              onClick={() => editor?.chain().focus().toggleStrike().run()}
            >
              <StrikethroughIcon
                className={cn("text-zinc-500 size-4", {
                  "text-blue-500": editor?.isActive("strike"),
                })}
              />
            </button>

            <div className="p-1">
              <div className="h-full w-px bg-zinc-200" />
            </div>

            <Tippy
              content={<LinkInput editor={editor} shown={showLinkInput} onClose={() => setShowLinkInput(false)} />}
              interactive={true}
              visible={showLinkInput}
              duration={0}
              placement="top-start"
              onClickOutside={() => setShowLinkInput(false)}
            >
              <button
                ref={linkButtonRef}
                className="rounded-lg p-2 hover:bg-zinc-950/5"
                onClick={(event) => {
                  event.preventDefault();
                  setShowLinkInput(!showLinkInput);
                }}
              >
                <LinkIcon className="text-zinc-500 size-4" />
              </button>
            </Tippy>
          </div>
        </BubbleMenu>
      )}
    </>
  );
}
