import * as Headless from "@headlessui/react";
import { useForm } from "@inertiajs/react";
import { useEffect, useState } from "react";
import { Button } from "../../components/button";
import { Checkbox } from "../../components/checkbox";
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from "../../components/dialog";
import DividerWithButton from "../../components/divider-with-button";
import { ErrorMessage, Field, FieldGroup, Fieldset, Label } from "../../components/fieldset";
import { Input } from "../../components/input";

export default function AddContactDialog({ isOpen, setIsOpen, fields, tags }) {
  const { data, errors, post, processing, recentlySuccessful, reset, setData, transform } = useForm({
    email: "",
    first_name: "",
    last_name: "",
    ...fields.reduce((acc, field) => {
      acc[`contact_fields.${field.id}`] = "";
      return acc;
    }, {}),
    ...tags.reduce((acc, tag) => {
      acc[`contact_tags.${tag.id}`] = false;
      return acc;
    }, {}),
  });
  const [isMoreFieldsShown, setIsMoreFieldsShown] = useState(false);

  useEffect(() => {
    if (recentlySuccessful) {
      setIsOpen(false);
      reset();
      setIsMoreFieldsShown(false);
    }
  }, [recentlySuccessful]);

  transform((data) => ({
    contact: {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      contact_fields_attributes: [
        ...fields
          .map((field) => ({ field_id: field.id, value: data[`contact_fields.${field.id}`] }))
          .filter((field) => field.value !== ""),
      ],
      contact_tags_attributes: [
        ...tags.filter((tag) => data[`contact_tags.${tag.id}`]).map((tag) => ({ tag_id: tag.id })),
      ],
    },
  }));

  return (
    <Dialog open={isOpen} onClose={setIsOpen}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          post("/contacts");
        }}
      >
        <DialogTitle>Add a contact</DialogTitle>
        <DialogDescription>
          Make sure you only add someone that already gave you permission to send them emails.
        </DialogDescription>
        <DialogBody>
          <Fieldset>
            <FieldGroup>
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4">
                <Field>
                  <Label>First name</Label>
                  <Input
                    name="first_name"
                    placeholder="John"
                    value={data.first_name}
                    onChange={(e) => setData("first_name", e.target.value)}
                    disabled={processing}
                    invalid={!!errors.first_name}
                  />
                  {errors.first_name && <ErrorMessage>{errors.first_name}</ErrorMessage>}
                </Field>
                <Field>
                  <Label>Last name</Label>
                  <Input
                    name="last_name"
                    placeholder="Doe"
                    value={data.last_name}
                    onChange={(e) => setData("last_name", e.target.value)}
                    disabled={processing}
                    invalid={!!errors.last_name}
                  />
                  {errors.last_name && <ErrorMessage>{errors.last_name}</ErrorMessage>}
                </Field>
              </div>
              <Field>
                <Label>Email</Label>
                <Input
                  type="email"
                  name="email"
                  placeholder="john.doe@example.com"
                  value={data.email}
                  onChange={(e) => setData("email", e.target.value)}
                  disabled={processing}
                  invalid={!!errors.email}
                />
                {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
              </Field>
              {tags.length > 0 && (
                <div>
                  <Label className="font-medium">Tags</Label>
                  <div className="mt-3 flex flex-wrap gap-x-5 gap-y-2">
                    {tags.map((tag) => (
                      <Headless.Field key={tag.id} className="flex items-center gap-3">
                        <Checkbox
                          name={tag.id}
                          checked={data[`contact_tags.${tag.id}`]}
                          onChange={(value) => setData(`contact_tags.${tag.id}`, value)}
                          disabled={processing}
                        />
                        <Label>{tag.name}</Label>
                      </Headless.Field>
                    ))}
                  </div>
                </div>
              )}
              {!isMoreFieldsShown ? (
                <DividerWithButton
                  className="!mt-12 !mb-4"
                  buttonText="Show more fields"
                  onClick={() => setIsMoreFieldsShown(true)}
                />
              ) : (
                fields.map((field) => (
                  <Field key={field.id}>
                    <Label>{field.name}</Label>
                    <Input
                      name={field.id}
                      placeholder={field.name}
                      value={data[`contact_fields.${field.id}`]}
                      onChange={(e) => setData(`contact_fields.${field.id}`, e.target.value)}
                      disabled={processing}
                      invalid={!!errors[`contact_fields.${field.id}`]}
                    />
                    {errors[`contact_fields.${field.id}`] && (
                      <ErrorMessage>
                        {errors[`contact_fields.${field.id}`].replace(`Contact fields ${field.id}`, field.name)}
                      </ErrorMessage>
                    )}
                  </Field>
                ))
              )}
            </FieldGroup>
          </Fieldset>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)} disabled={processing}>
            Cancel
          </Button>
          <Button color="violet" type="submit" disabled={processing}>
            Add contact
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
