import { ClipboardIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { Button } from "../../components/button";
import { Subheading } from "../../components/heading";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/table";

export default function DnsRecordsTable({ title, records }) {
  function copyToClipboard(text) {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard.");
  }

  return (
    <div className="mt-12">
      <Subheading>{title}</Subheading>

      <Table className="mt-4 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
        <TableHead>
          <TableRow>
            <TableHeader>Type</TableHeader>
            <TableHeader>Name</TableHeader>
            <TableHeader>Value</TableHeader>
            <TableHeader>Priority</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((record) => (
            <TableRow key={record.id}>
              <TableCell>{record.record_type.toUpperCase()}</TableCell>
              <TableCell>
                <div className="flex items-center gap-2">
                  {record.name}
                  <Button plain aria-label="Copy to clipboard" onClick={() => copyToClipboard(record.name)}>
                    <ClipboardIcon />
                  </Button>
                </div>
              </TableCell>
              <TableCell>
                <div className="flex items-center gap-2">
                  {record.value}
                  <Button plain aria-label="Copy to clipboard" onClick={() => copyToClipboard(record.value)}>
                    <ClipboardIcon />
                  </Button>
                </div>
              </TableCell>
              <TableCell>
                <div className="flex items-center gap-2">
                  {record.priority ? (
                    <>
                      {record.priority}
                      <Button plain aria-label="Copy to clipboard" onClick={() => copyToClipboard(record.priority)}>
                        <ClipboardIcon />
                      </Button>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
