import { ChevronLeftIcon } from "@heroicons/react/16/solid";
import { Head } from "@inertiajs/react";
import { useState } from "react";
import { Button } from "../../components/button";
import { Heading, Subheading } from "../../components/heading";
import { Link } from "../../components/link";
import { cn } from "../../lib/utils";
import List from "../contacts/list";
import FilterGroups from "./filter-groups";

export default function Show({ segment, filters, contacts, fields, contacts_copy, existing_filters }) {
  const [showFilterGroups, setShowFilterGroups] = useState(existing_filters.length > 0);

  return (
    <>
      <Head title={segment.name} />

      <div className="max-lg:hidden">
        <Link href="/segments" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
          <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
          <span className="max-w-48 line-clamp-1">Segments</span>
        </Link>
      </div>

      <div className="mt-4 lg:mt-8">
        <div className="flex flex-wrap justify-between items-end gap-x-6 gap-y-4">
          <div className="flex items-center gap-4">
            <Heading>{segment.name}</Heading>
          </div>
          <div className="flex gap-4">
            <Button
              color="violet"
              className={cn(showFilterGroups && "invisible")}
              onClick={(event) => {
                event.preventDefault();
                setShowFilterGroups(true);
              }}
            >
              Add filter
            </Button>
          </div>
        </div>
      </div>

      {showFilterGroups && (
        <div className="mt-12">
          <FilterGroups existingFilters={existing_filters} filters={filters} segment={segment} />
        </div>
      )}

      <div className={cn(showFilterGroups ? "mt-14" : "mt-12")}>
        <Subheading>{contacts_copy}</Subheading>
        <div className="-mt-4">
          <List contacts={contacts} fields={fields} />
        </div>
      </div>
    </>
  );
}
