import { Plugin, PluginKey } from "@tiptap/pm/state";

export const KEYDOWN_KEY = "eventDispatcherKeydown";
export const MOUSEMOVE_KEY = "eventDispatcherMousemove";
export const IMAGE_RESIZE_KEY = "eventDispatcherImageResize";

export function createEventDispatcherPlugin() {
  return new Plugin({
    key: new PluginKey("eventDispatcherPlugin"),
    props: {
      handleKeyDown(view, event) {
        view.dispatch(view.state.tr.setMeta(KEYDOWN_KEY, { event }));
      },
      handleDOMEvents: {
        mousemove: (view, event) => {
          view.dispatch(view.state.tr.setMeta(MOUSEMOVE_KEY, { event }));
        },
      },
    },
  });
}

export function dispatchImageResize(view, imageDimension) {
  view.dispatch(view.state.tr.setMeta(IMAGE_RESIZE_KEY, imageDimension));
}
