import { ChevronLeftIcon } from "@heroicons/react/16/solid";
import { Head, useForm } from "@inertiajs/react";
import LinkExtension from "@tiptap/extension-link";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect, useState } from "react";
import { Button } from "../../components/button";
import Editor from "../../components/editor";
import { Description, ErrorMessage, Field, FieldGroup, Fieldset, Label, Legend } from "../../components/fieldset";
import { Heading, Subheading } from "../../components/heading";
import { Input } from "../../components/input";
import { Link } from "../../components/link";
import { Listbox, ListboxDescription, ListboxLabel, ListboxOption } from "../../components/listbox";
import { Select } from "../../components/select";
import { Text } from "../../components/text";
import { useAutoSave } from "../../hooks/use-auto-save";
import { cn, getEditorNodes } from "../../lib/utils";
import { DraggableDivider, DraggableImage } from "../../tiptap/extensions/draggable";
import { DynamicField } from "../../tiptap/extensions/dynamic-field";
import SendAlert from "./send-alert";

export default function Review({ campaign, contacts, contacts_copy, senders }) {
  const { errors, processing, post } = useForm();
  const { content, subject, setSubject, senderId, setSenderId, previewText, setPreviewText } = useAutoSave({
    saveEndpoint: `/campaigns/${campaign.id}`,
    saveInterval: 1000,
    initialSubject: campaign.subject,
    initialContent: campaign.json_content,
    initialSenderId: campaign.sender_id,
    initialPreviewText: campaign.preview_text,
  });
  const editor = useEditor({
    editable: false,
    content: !!content && JSON.parse(content),
    editorProps: {
      attributes: {
        class: cn("prose prose-base prose-zinc mx-auto max-w-[708px] caret-zinc-900 focus:outline-none"),
      },
    },
    extensions: [
      StarterKit.configure({ horizontalRule: false }),
      Typography,
      LinkExtension.extend({ inclusive: false }).configure({
        HTMLAttributes: {
          class: "hover:cursor-pointer",
        },
      }),
      DraggableDivider,
      DraggableImage,
      Underline,
      DynamicField,
    ],
  });
  const [isSendAlertOpen, setIsSendAlertOpen] = useState(false);
  const [selectedPreviewEmail, setSelectedPreviewEmail] = useState(contacts[0]?.email);

  useEffect(() => {
    getEditorNodes(editor, "dynamicField").forEach(({ node, pos }) => {
      const currentContact = contacts.find((contact) => contact.email === selectedPreviewEmail);

      editor.view.dispatch(
        editor.view.state.tr.setNodeMarkup(pos, null, {
          ...node.attrs,
          value: currentContact[node.attrs.label]?.toString() || node.attrs.fallback,
        })
      );
    });
  }, [selectedPreviewEmail]);

  return (
    <>
      <Head title={`Campaign review: ${subject}`} />

      <div className="max-lg:hidden">
        <Link
          href={`/campaigns/${campaign.id}/edit`}
          className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400"
        >
          <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
          <span className="max-w-48 line-clamp-1">{subject}</span>
        </Link>
      </div>

      <div className="mt-4 lg:mt-8">
        <div className="flex flex-wrap justify-between items-end gap-x-6 gap-y-4">
          <div className="flex items-center gap-4">
            <Heading>Campaign review</Heading>
          </div>
          <div className="flex gap-4">
            <Button color="violet" onClick={() => setIsSendAlertOpen(true)} disabled={processing}>
              Send
            </Button>
          </div>
        </div>
      </div>

      <div className="mt-12 grid gap-16 sm:grid-cols-2">
        <Fieldset disabled={processing}>
          <Legend>Details</Legend>
          <Text>Make final changes to your campaign settings before sending.</Text>
          <FieldGroup>
            <Field>
              <Label>Subject</Label>
              <Input
                name="subject"
                placeholder="Welcome onboard!"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </Field>
            <Field>
              <Label>Preview text</Label>
              <Input
                name="preview_text"
                placeholder="We are so excited and looking forward to your arrival."
                value={previewText}
                onChange={(e) => setPreviewText(e.target.value)}
              />
            </Field>
            <Field>
              <Label>Sender</Label>
              <Listbox name="sender" value={senderId} onChange={setSenderId} invalid={!!errors.sender}>
                {senders.map((sender) => (
                  <ListboxOption key={sender.id} value={sender.id}>
                    <ListboxLabel>{sender.name}</ListboxLabel>
                    <ListboxDescription>{sender.email}</ListboxDescription>
                  </ListboxOption>
                ))}
              </Listbox>
              {errors.sender && <ErrorMessage>{errors.sender}</ErrorMessage>}
            </Field>
            <Field disabled>
              <Label>Target segments</Label>
              <Input name="target_segments" value="All contacts" />
              <Description>{contacts_copy}</Description>
            </Field>
          </FieldGroup>
        </Fieldset>
        <div>
          {contacts.length > 0 ? (
            <div className="flex items-center gap-x-3">
              <Subheading>Preview for</Subheading>
              <div>
                <Select
                  name="contact"
                  value={selectedPreviewEmail}
                  onChange={(e) => setSelectedPreviewEmail(e.target.value)}
                >
                  {contacts.map((contact) => (
                    <option key={contact.id} value={contact.email}>
                      {contact.email}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          ) : (
            <Subheading>Preview</Subheading>
          )}
          <div className="mt-4 rounded-xl ring-1 ring-zinc-950/10 dark:ring-white/10 forced-colors:outline">
            <div className="h-[560px] max-h-[560px] overflow-auto p-[--gutter] [--gutter:theme(spacing.8)]">
              <Editor editor={editor} />
            </div>
          </div>
        </div>
      </div>

      <SendAlert
        isOpen={isSendAlertOpen}
        setIsOpen={setIsSendAlertOpen}
        contactsCopy={contacts_copy}
        onSend={() => post(`/campaigns/${campaign.id}/broadcast`)}
      />
    </>
  );
}
