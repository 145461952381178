import { useForm } from "@inertiajs/react";
import { useEffect } from "react";
import { Button } from "../../components/button";
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from "../../components/dialog";
import { ErrorMessage, Field, FieldGroup, Fieldset, Label } from "../../components/fieldset";
import { Input } from "../../components/input";

export default function AddSenderDialog({ isOpen, setIsOpen }) {
  const { data, errors, post, processing, recentlySuccessful, reset, setData } = useForm({
    name: "",
    email: "",
  });

  useEffect(() => {
    if (recentlySuccessful) {
      reset();
      setIsOpen(false);
    }
  }, [recentlySuccessful]);

  return (
    <Dialog open={isOpen} onClose={setIsOpen}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          post("/senders");
        }}
      >
        <DialogTitle>Add a sender</DialogTitle>
        <DialogDescription>
          Please use an email address from a domain that you own. Currently, only verified domains are allowed to send
          emails.
        </DialogDescription>
        <DialogBody>
          <Fieldset>
            <FieldGroup>
              <Field>
                <Label>Name</Label>
                <Input
                  name="name"
                  placeholder="John Doe"
                  value={data.name}
                  onChange={(e) => setData("name", e.target.value)}
                  disabled={processing}
                  invalid={!!errors.name}
                  required
                />
                {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
              </Field>
              <Field>
                <Label>Email</Label>
                <Input
                  type="email"
                  name="email"
                  placeholder="john.doe@example.com"
                  value={data.email}
                  onChange={(e) => setData("email", e.target.value)}
                  disabled={processing}
                  invalid={!!errors.email}
                  required
                />
                {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
              </Field>
            </FieldGroup>
          </Fieldset>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)} disabled={processing}>
            Cancel
          </Button>
          <Button color="violet" type="submit" disabled={processing}>
            Add sender
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
