import { cn } from "../lib/utils";
import { Button } from "./button";
import { Divider } from "./divider";

export default function DividerWithButton({ className, buttonText, onClick }) {
  return (
    <div className={cn("relative", className)}>
      <div aria-hidden="true" className="absolute inset-0 flex items-center">
        <Divider />
      </div>
      <div className="relative flex justify-center">
        <Button plain className="bg-white" onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
}
