import * as Headless from "@headlessui/react";
import { LinkSlashIcon } from "@heroicons/react/16/solid";
import { useEffect, useRef, useState } from "react";
import { Button } from "../components/button";
import { Input } from "../components/input";

export default function LinkInput({ editor, shown, onClose }) {
  const [isLinked, setIsLinked] = useState(false);
  const [link, setLink] = useState("");
  const linkInputRef = useRef(null);

  useEffect(() => {
    if (shown) {
      const hasLinked = editor?.isActive("link") ?? false;

      setIsLinked(hasLinked);
      setLink(hasLinked ? editor?.getAttributes("link").href : "");

      linkInputRef.current?.focus();
    } else {
      setLink("");
      editor?.commands.focus();
    }
  }, [shown]);

  useEffect(() => {
    if (linkInputRef.current) {
      linkInputRef.current.scrollLeft = linkInputRef.current.scrollWidth;
    }
  }, [link]);

  return (
    <Headless.Field className="dark:bg-zinc-800 bg-white isolate w-max rounded-xl p-1 outline outline-1 outline-transparent focus:outline-none dark:ring-inset dark:ring-white/10 shadow-lg ring-1 ring-zinc-950/10">
      <form
        className="flex items-center justify-center gap-0.5"
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();

          if (event.target.checkValidity() === false) {
            event.target.reportValidity();
            return;
          }

          editor?.chain().focus().setLink({ href: link }).run();
          onClose();
        }}
      >
        <Input
          ref={linkInputRef}
          type="url"
          placeholder="Paste your link"
          aria-label="Link"
          name="link"
          className="*:px-2 *:py-0.5 *:sm:px-2 *:sm:py-0.5"
          value={link}
          onKeyDown={(event) => {
            if (event.key === "Escape") {
              onClose();
            }
          }}
          onChange={(event) => setLink(event.target.value)}
        />
        <Button type="submit" className="ml-1 !px-2 !py-1">
          Apply
        </Button>
        {isLinked && (
          <Button
            plain
            className="ml-1 !px-2 !py-1"
            onClick={(event) => {
              event.preventDefault();
              editor?.chain().focus().unsetLink().run();
              onClose();
            }}
          >
            <LinkSlashIcon className="size-4" />
          </Button>
        )}
      </form>
    </Headless.Field>
  );
}
