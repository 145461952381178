import { DocumentArrowUpIcon, DocumentTextIcon } from "@heroicons/react/16/solid";
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from "../../components/dropdown";

export default function AddContactDropdown({ onFillForm, onUploadCsv, isContactEmpty = false }) {
  return (
    <Dropdown>
      <DropdownButton color="violet">Add contact</DropdownButton>
      <DropdownMenu anchor={!isContactEmpty ? "bottom end" : "bottom center"}>
        <DropdownItem onClick={onFillForm}>
          <DocumentTextIcon />
          <DropdownLabel>Fill a form</DropdownLabel>
        </DropdownItem>
        <DropdownItem onClick={onUploadCsv}>
          <DocumentArrowUpIcon />
          <DropdownLabel>Upload CSV</DropdownLabel>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
