import { ChevronLeftIcon } from "@heroicons/react/16/solid";
import { Head, useForm } from "@inertiajs/react";
import { useMemo } from "react";
import { Badge } from "../../components/badge";
import { Button } from "../../components/button";
import { Heading } from "../../components/heading";
import { Link } from "../../components/link";
import DnsRecordsTable from "./dns-records-table";

export default function Verification({ current_user, path, sender, dns_records, status }) {
  const { processing, post } = useForm();
  const { dkim, spf, dmarc } = useMemo(() => {
    return dns_records.reduce(
      (acc, record) => {
        const type = record.auth_type;
        if (!acc[type]) acc[type] = [];
        acc[type].push(record);
        return acc;
      },
      { dkim: [], spf: [], dmarc: [] }
    );
  }, [dns_records]);

  return (
    <>
      <Head title={`Verification of ${sender.email}`} />

      <div className="max-lg:hidden">
        <Link href="/senders" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
          <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
          Senders
        </Link>
      </div>

      <div className="mt-4 lg:mt-8">
        <div className="flex flex-wrap justify-between items-end gap-x-6 gap-y-4">
          <div className="flex items-center gap-4">
            <Heading>Verification of {sender.email}</Heading>
            {status === "verified" ? (
              <Badge color="lime">Verified</Badge>
            ) : status === "in progress" ? (
              <Badge color="yellow">In progress</Badge>
            ) : (
              <Badge color="pink">Unverified</Badge>
            )}
          </div>
          <div className="flex gap-4">
            <Button
              outline
              onClick={() => (window.location.href = `/senders/${sender.id}/verification/download-records`)}
            >
              Download DNS records
            </Button>
            {status === "unverified" && (
              <Button
                color="violet"
                onClick={() => {
                  post(`/senders/${sender.id}/start-verification`);
                }}
                disabled={processing}
              >
                Verify
              </Button>
            )}
          </div>
        </div>
      </div>

      <DnsRecordsTable title="DKIM" records={dkim} />
      <DnsRecordsTable title="SPF" records={spf} />
      <DnsRecordsTable title="DMARC" records={dmarc} />
    </>
  );
}
