import { useForm } from "@inertiajs/react";
import { useEffect } from "react";
import { Button } from "../../components/button";
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from "../../components/dialog";
import { ErrorMessage, Field, FieldGroup, Fieldset, Label } from "../../components/fieldset";
import { Input } from "../../components/input";
import { Select } from "../../components/select";

export default function AddFieldDialog({ isOpen, setIsOpen }) {
  const { data, errors, post, processing, recentlySuccessful, reset, setData } = useForm({
    name: "",
    data_type: "text",
  });

  useEffect(() => {
    if (recentlySuccessful) {
      reset();
      setIsOpen(false);
    }
  }, [recentlySuccessful]);

  return (
    <Dialog open={isOpen} onClose={setIsOpen}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          post("/fields");
        }}
      >
        <DialogTitle>Add a field</DialogTitle>
        <DialogDescription>
          Use fields to describe your contacts better. Note that the field type can't be changed later.
        </DialogDescription>
        <DialogBody>
          <Fieldset>
            <FieldGroup>
              <Field>
                <Label>Name</Label>
                <Input
                  name="name"
                  placeholder="Address"
                  value={data.name}
                  onChange={(e) => setData("name", e.target.value)}
                  disabled={processing}
                  invalid={!!errors.name}
                />
                {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
              </Field>
              <Field>
                <Label>Type</Label>
                <Select
                  name="type"
                  value={data.data_type}
                  onChange={(e) => setData("data_type", e.target.value)}
                  disabled={processing}
                  invalid={!!errors.data_type}
                >
                  <option value="text">Text</option>
                  <option value="number">Number</option>
                  <option value="date">Date</option>
                </Select>
                {errors.data_type && <ErrorMessage>{errors.data_type}</ErrorMessage>}
              </Field>
            </FieldGroup>
          </Fieldset>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)} disabled={processing}>
            Cancel
          </Button>
          <Button color="violet" type="submit" disabled={processing}>
            Add field
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
