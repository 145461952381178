import Heading from "@tiptap/extension-heading";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Image from "@tiptap/extension-image";
import ListItem from "@tiptap/extension-list-item";
import Paragraph from "@tiptap/extension-paragraph";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { createEventDispatcherPlugin } from "../../prose-mirror/plugins/event-dispatcher";
import { BlockHandle } from "../node-views/block-handle";

export const DraggableParagraph = Paragraph.extend({
  draggable: true,

  addProseMirrorPlugins() {
    return [createEventDispatcherPlugin()];
  },

  addNodeView() {
    return ReactNodeViewRenderer(BlockHandle);
  },
});

export const DraggableHeading = Heading.extend({
  draggable: true,

  addProseMirrorPlugins() {
    return [createEventDispatcherPlugin()];
  },

  addNodeView() {
    return ReactNodeViewRenderer(BlockHandle);
  },
});

export const DraggableDivider = HorizontalRule.extend({
  draggable: true,

  addProseMirrorPlugins() {
    return [createEventDispatcherPlugin()];
  },

  addNodeView() {
    return ReactNodeViewRenderer(BlockHandle);
  },
});

export const DraggableListItem = ListItem.extend({
  draggable: true,

  addProseMirrorPlugins() {
    return [createEventDispatcherPlugin()];
  },

  addNodeView() {
    return ReactNodeViewRenderer(BlockHandle);
  },
});

export const DraggableImage = Image.extend({
  draggable: true,

  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: null,
        parseHTML: (element) => element.getAttribute("width"),
        renderHTML: (attributes) => {
          if (!attributes.width) {
            return {};
          }
          return {
            width: attributes.width,
          };
        },
      },
      originalWidth: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-original-width"),
        renderHTML: (attributes) => {
          if (!attributes.originalWidth) {
            return {};
          }
          return {
            "data-original-width": attributes.originalWidth,
          };
        },
      },
      originalHeight: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-original-height"),
        renderHTML: (attributes) => {
          if (!attributes.originalHeight) {
            return {};
          }
          return {
            "data-original-height": attributes.originalHeight,
          };
        },
      },
      alignment: {
        default: "left",
        rendered: false,
      },
      src: {
        default: null,
        parseHTML: (element) => element.getAttribute("src"),
        renderHTML: (attributes) => {
          if (!attributes.src) {
            return {};
          }
          return {
            src: attributes.src,
          };
        },
      },
    };
  },

  addProseMirrorPlugins() {
    return [createEventDispatcherPlugin()];
  },

  addNodeView() {
    return ReactNodeViewRenderer(BlockHandle);
  },
});
