import { Head } from "@inertiajs/react";
import { useState } from "react";
import { Button } from "../../components/button";
import { Heading } from "../../components/heading";
import Tab from "../../components/tab";
import { cn } from "../../lib/utils";
import AddSegmentDialog from "./add-segment-dialog";
import EditSegmentDialog from "./edit-segment-dialog";
import Empty from "./empty";
import List from "./list";

const tabs = [
  { name: "All contacts", href: "/contacts", current: false },
  { name: "Segments", href: "/segments", current: true },
  { name: "Tags", href: "/tags", current: false },
  { name: "Fields", href: "/fields", current: false },
];

export default function Index({ segments }) {
  const [isAddSegmentDialogOpen, setIsAddSegmentDialogOpen] = useState(false);
  const [isEditSegmentDialogOpen, setIsEditSegmentDialogOpen] = useState(false);
  const [editingSegment, setEditingSegment] = useState(null);

  return (
    <>
      <Head title="Segments" />

      <div className="flex items-end justify-between gap-4">
        <Heading>Contacts</Heading>
        <div className="flex gap-4">
          <Button
            color="violet"
            className={cn(segments.length === 0 && "invisible")}
            onClick={() => setIsAddSegmentDialogOpen(true)}
          >
            Add segment
          </Button>
        </div>
      </div>

      <Tab tabs={tabs} />

      {segments.length > 0 ? (
        <List
          segments={segments}
          onEdit={(segment) => {
            setEditingSegment(segment);
            setIsEditSegmentDialogOpen(true);
          }}
        />
      ) : (
        <Empty onAdd={() => setIsAddSegmentDialogOpen(true)} />
      )}

      <AddSegmentDialog isOpen={isAddSegmentDialogOpen} setIsOpen={setIsAddSegmentDialogOpen} />
      <EditSegmentDialog
        isOpen={isEditSegmentDialogOpen}
        setIsOpen={setIsEditSegmentDialogOpen}
        segment={editingSegment}
      />
    </>
  );
}
