import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { DynamicField as DynamicFieldNodeView } from "../node-views/dynamic-field";

export const DynamicField = Node.create({
  name: "dynamicField",
  group: "inline",
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      label: {
        default: null,
      },
      value: {
        default: null,
      },
      fallback: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span[data-dynamic-field]",
      },
    ];
  },

  renderHTML({ node }) {
    return [
      "span",
      {
        "data-dynamic-field": "",
      },
      `{{${node.attrs.label}}}`,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(DynamicFieldNodeView);
  },
});
