import * as Headless from "@headlessui/react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/16/solid";
import { NodeViewWrapper } from "@tiptap/react";
import { useState } from "react";
import { Button } from "../../components/button";
import { Input } from "../../components/input";
import { cn } from "../../lib/utils";

export function DynamicField({ editor, node, getPos }) {
  const [fallbackText, setFallbackText] = useState(node.attrs.fallback || "");
  const value = editor.isEditable ? `{{${node.attrs.label}}}` : node.attrs.value || `{{${node.attrs.label}}}`;

  function handleUpdateFallback(event, close) {
    event.preventDefault();

    editor.view.dispatch(
      editor.view.state.tr.setNodeMarkup(getPos(), null, {
        ...node.attrs,
        fallback: fallbackText === "" ? null : fallbackText,
      })
    );

    close();

    requestAnimationFrame(() => {
      editor.commands.focus();
    });
  }

  function handleDelete() {
    editor.view.dispatch(editor.state.tr.delete(getPos(), getPos() + node.nodeSize));

    requestAnimationFrame(() => {
      editor.commands.focus();
    });
  }

  return (
    <NodeViewWrapper
      as="span"
      className={cn(
        "inline-flex",
        editor.isEditable &&
          "items-center gap-x-1.5 rounded-md px-1 font-medium forced-colors:outline bg-cyan-400/20 text-cyan-700 hover:bg-cyan-400/30 dark:bg-cyan-400/10 dark:text-cyan-300 dark:hover:bg-cyan-400/15"
      )}
    >
      <Headless.Popover className="relative">
        {({ open }) => {
          if (!open) {
            setTimeout(() => {
              setFallbackText(node.attrs.fallback || "");
            }, 100);
          }

          return (
            <>
              <Headless.PopoverButton className="flex items-center gap-x-1" disabled={!editor.isEditable}>
                {value}
                {editor.isEditable && <PencilSquareIcon className="size-4" />}
              </Headless.PopoverButton>
              <Headless.PopoverPanel
                anchor="top center"
                focus
                transition
                className={cn(
                  // Anchor positioning
                  "[--anchor-gap:theme(spacing.2)] [--anchor-padding:theme(spacing.1)] data-[anchor~=start]:[--anchor-offset:-6px] data-[anchor~=end]:[--anchor-offset:6px] sm:data-[anchor~=start]:[--anchor-offset:-4px] sm:data-[anchor~=end]:[--anchor-offset:4px]",
                  // Base styles
                  "isolate w-max rounded-xl p-1",
                  // Invisible border that is only visible in `forced-colors` mode for accessibility purposes
                  "outline outline-1 outline-transparent focus:outline-none",
                  // Handle scrolling when menu won't fit in viewport
                  "overflow-y-auto",
                  // Popover background
                  "bg-white/75 backdrop-blur-xl dark:bg-zinc-800/75",
                  // Shadows
                  "shadow-lg ring-1 ring-zinc-950/10 dark:ring-inset dark:ring-white/10",
                  // Transitions
                  "transition data-[closed]:data-[leave]:opacity-0 data-[leave]:duration-100 data-[leave]:ease-in"
                )}
              >
                {({ close }) => (
                  <form className="flex gap-x-1" onSubmit={(event) => handleUpdateFallback(event, close)}>
                    <Input
                      aria-label="Fallback text"
                      name="fallback"
                      placeholder="Fallback when the field is empty"
                      value={fallbackText}
                      onChange={(e) => setFallbackText(e.target.value)}
                    />
                    <Button type="submit">Apply</Button>
                    <Button plain className="hover:!bg-pink-100 group" onClick={handleDelete}>
                      <TrashIcon className=" group-hover:text-pink-600" />
                    </Button>
                  </form>
                )}
              </Headless.PopoverPanel>
            </>
          );
        }}
      </Headless.Popover>
    </NodeViewWrapper>
  );
}
