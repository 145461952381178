import { cn } from "../lib/utils";
import { Link } from "./link";
import { Select } from "./select";

export default function Tab({ tabs }) {
  return (
    <div className="mt-8">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <Select id="tabs" name="tabs" aria-label="Contacts tabs" defaultValue={tabs.find((tab) => tab.current).name}>
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </Select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-zinc-200">
          <nav aria-label="Tabs" className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <Link
                key={tab.name}
                href={tab.href}
                aria-current={tab.current ? "page" : undefined}
                className={cn(
                  tab.current
                    ? "border-violet-500 text-violet-600"
                    : "border-transparent text-zinc-500 hover:border-zinc-300 hover:text-zinc-700",
                  "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                )}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
